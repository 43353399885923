import {
  Category,
  CloudSubType,
  CloudTypes,
  Direction,
  Nullable,
  RepoTypes,
  Severities,
} from 'ox-common-types';
import { FilterElements } from 'ox-filter-utils';
import { ArtifactorySecEventType, CiCdTypes } from 'ox-react-components';
import { SupplyChainResponseItem } from '../../supply-chain/types/supply-chain-backend-types';
import { SupplyChainItem } from '../../supply-chain/types/supplychain-types';
import { ScaVulnerability } from '../../issues/common/types/issues-types';

export interface ArtifactInfo {
  id: string;
  appDescription?: ArtifactApplication;
  artifactInfo: IArtifactInfo;
  categories?: Category[];
  cicdDescription?: CICDArtifactDescription;
  cloudData: CloudArtifactData[];
  codeDescription?: CodeArtifactDescription;
  totalIssuesBySeverity: Severities;
  registryDescription: ArtifactRegistryDescription[];
  supplyChainInfo: SupplyChainResponseItem[];
  supplyChainItems: SupplyChainItem[];
  reachableVulnerabilities: ScaVulnerability[];
  nonReachableVulnerabilities: ScaVulnerability[];

  packages: ArtifactPackage[];
  vulnDepIssues?: string[];
  vulnDepBaseIssues?: string[];
  vulnDepInstructionIssues?: string[];
  secretIssues?: string[];
  confIssues?: string[];
}
export interface ArtifactRegistryDescription {
  type: RegistryTypes;
  name: string;
  project: string;
  link: string;
  hash: string;
  tags: string[];
  username: string;
  userType: string;
  uploadTime: number;
  lastUpdate: number;
  buildTime: number;
}

export interface CodeArtifactDescription {
  commit: string;
  commitLink: string;
  fileChanged: FileChanged[];
  mergeLink: string;
  prApprovals: string[];
  prDate: string;
  prOwner: string;
  prReviewers: string[];
  scm: CodeArtifactSCMTypes;
  sourceBranch: string;
  destinationBranch: string;
  isPublic: boolean;
}

export enum CodeArtifactSCMTypes {
  Github = 'GitHub',
  Azure = 'Azure',
  AWSCodeBuild = 'AWS CodeBuild',
  GitLab = 'GitLab',
  Bitbucket = 'Bitbucket',
  Bitbucketstash = 'Bitbucket stash',
  AzureContainerRegistry = 'Azure Container Registry',

  AzureReposTFVC = 'Azure Repos (TFVC)',
  AzureReposGit = 'Azure Repos (Git)',

  AzureTFS = 'Azure TFS',
  Gerrit = 'Gerrit',
}

export interface FileChanged {
  change: string;
  fileName: string;
}

export interface CloudArtifactData {
  cloudIdentifier?: string;
  link: string;
  lastExecutionTime?: Date;
  lastModifiedTime?: Date;
  account?: string;
  zone?: string;
  cloudDescription: CloudDescription;
}

export interface CommonCloudAttributes {
  registeredAt: Date;
  registeredBy?: string;
  account?: string;
  zone?: string;
}

export interface ECS extends CommonCloudAttributes {
  os: string;
  cpu: string;
  memory: string;
  containers: string;
}

export interface Lambda extends CommonCloudAttributes {
  runtime: string;
  handler: string;
  size: string;
}

export type CloudEntityAttributes = ECS | Lambda;

export interface CloudDescription {
  cloudEntityAttributes?: ECS | Lambda;
  subType: CloudSubType;
  type: CloudTypes;
}

export interface CICDArtifactDescription {
  id: string;
  pipeline: PipelineStatistics;
  pipelineExecution?: PipelineExecution;
  type: CICDSystems;
}

export enum CICDSystems {
  Jenkins = 'Jenkins',
  GithubActions = 'GithubActions',
  Gitlab = 'Gitlab',
  Github = 'GitHub',
}
export interface PipelineExecution {
  link: string;
  pipelineId: string;
  pushType: string;
  executedBy: string;
  createTime: number;
  updateTime: number;
  status: string;
  intermediateRegistry: string[];
  pipelineName: string;
}

export interface PipelineStatistics {
  id: string;
  link: string;
  owner: string;
  developer: string;
  creationTime: number;
  lastExecutionTime: number;
  executed: number;
}

export interface IArtifactInfo {
  hash: string;
  name: string;
  type: ArtifactInfoTypes;
  version: string;
  artifactIntegrity: string;
}

export interface ArtifactApplication {
  appId: string;
  appName: string;
  toolInfo: ToolInfo[];
  businessPriority: number;
  appType: string;
}

export interface ToolInfo {
  toolName: string;
  name: string[];
  total: number;
  category: string;
  criticality: number;
}

export enum ArtifactInfoTypes {
  DockerContainer = 'Docker Container',
  PackageArtifact = 'Package Artifact',
  Lambda = 'Lambda',
  NPM = 'NPM',
  Yarn = 'Yarn',
  Serverless = 'Serverless',
  Container = 'Container',
}

export interface ArtifactCodeInfo {
  url: string;
  repoType: RepoTypes;
}

export interface ArtifactCicdInfo {
  url: string;
  cicdType: CiCdTypes;
}

export interface ArtifactRegistryInfo {
  url: string;
  registryType: ArtifactorySecEventType;
}

export interface ArtifactDetails {
  id: string;
}

export interface FilterArtifactsType {
  artifactName?: FilterElements[];
  apps?: FilterElements[];
  artifactTypes?: FilterElements[];
  cicd?: FilterElements[];
  cloud?: FilterElements[];
  environment?: FilterElements[];
  executionStatus?: FilterElements[];
  enviorment?: FilterElements[];
  issueCategories?: FilterElements[];
  issueSeverities?: FilterElements[];
  registryType?: FilterElements[];
  sourceType?: FilterElements[];
  version?: FilterElements[];
  total?: number;
}
export interface FetchArtifactsResponse {
  getArtifacts: FetchArtifactsData;
  getArtifact: ArtifactInfo;
}

export interface FetchArtifactsData {
  artifacts: ArtifactInfo[];
  total: number;
  offset: number;
  totalFilteredArtifacts: number;
}

export interface FetchArtifactsInput {
  offset?: number;
  limit?: number;
  filters?: FilterArtifactsType;
  artifactTopFilters?: ArtifactTopFiltersTypes[];
  sort?: {
    fields: Nullable<ArtifactsSortKey>[];
    order: Direction;
  };
  dateRange?: {
    from: number;
    to: number;
  };
  search: string;
  owners: string[];
  openItems?: string[];
}

export enum ArtifactTopFiltersTypes {
  Executed = 'Executed',
  WithHighSeverityIssues = 'WithHighSeverityIssues',
  InProductionCloud = 'InProductionCloud',
  LastVersion = 'LastVersion',
  DeployedToCloud = 'DeployedToCloud',
}

export interface FetchSingleArtifactInput {
  artifactId: string;
}

export type LoadArtifactsParams = {
  isLoadMoreItems?: boolean;
  cache?: boolean;
  limit?: number;
};

export type GetArtifactsFilters = {
  type?: string;
  isOpen?: boolean;
  cache?: boolean;
};

export enum RegistryTypes {
  JFrog = 'JFrog',
  ECR = 'ECR',
  GCP = 'GCP',
  AmazonECR = 'Amazon ECR',
  Lambda = 'Lambda',
  Generic = 'Generic',
  GoogleArtifactRegistry = 'Google Artifact Registry',
  GoogleArtifactContainer = 'Google Container Registry',
  GitlabRegistry = 'Gitlab Registry',
  Container = 'Container',
  DockerHub = 'Docker Hub',
  JFrogArtifactory = 'JFrog Artifactory',
  ACR = 'Azure Container Registry',
}

export enum CloudThirdPartyTypes {
  Wiz = 'wiz',
  Orca = 'orca',
  Prisma = 'prisma', // bc
  PrismaCspm = 'prisma cspm',
  PrismaArtifacts = 'prisma artifacts',
}

export enum ArtifactsSortKey {
  ArtifactType = 'ArtifactType',
}

export interface ArtifactTopFilter {
  count: number;
  delta: number;
  label: string;
  name: string;
  trend: string;
  isDevelopment?: boolean;
}

export interface ArtifactPackage {
  id?: number;
  appId: string;
  appName: string;
  repoName: string;
  link: string;
  type: string;
}
