import {
  AppOwner,
  AppOwnerRole,
  Category,
  IconType,
  Nullable,
  OneOfValues,
  Order,
  RepoTypes,
  SbomType,
  SeverityAlert,
} from 'ox-common-types';
import { FilterElements, FilterBp, ConditionalFilter } from 'ox-filter-utils';
import { ApplicationFlow } from 'ox-react-components';
import { InventoryFilterType } from '../dashboard-inventory/inventory-types';
import { ApplicationsFilters } from '../irrelevant-apps/irrelevant-apps-types';
import {
  PermissionScope,
  PermissionTypes,
} from '@oxappsec/ox-unified-permissions';

export interface BaseApplicationInfo {
  new: boolean;
  risk: number;
  type: RepoTypes;
  size: number;
  appId: string;
  issues: number;

  scanId: string;
  branch: string;
  creator: string;
  appName: string;
  version: string;
  updated: boolean;
  fakeApp: boolean;
  relevant: boolean;
  originalBusinessPriority: number;
  scannedAt: string;
  userCount: number;
  pushCount: number;
  pullCount: number;
  tagsCount: number;
  createdAt: string;
  yamlsCount: number;
  filesCount: number;
  forksCount: number;
  codeChanges: number;
  dockerfiles: { path: string }[];
  commitCount: number;
  deployedProd: boolean;
  hasDownloads: boolean;
  branchesCount: number;
  watchersCount: number;
  violationCount: number;
  lastCodeChange: string;
  securityPosture: number;
  overridePriority: number;
  publicVisibility: boolean;
  businessPriority: number;
  irrelevantReasons: string[];
  overrideRelevance: string;
  daysSinceRepoCreation: number;
  daysSinceLastCodeChange: number;
  monorepoChildrenAppIds: string[];
  sbomCount: number;
  languages: {
    language: string;
    languagePercentage: number;
  }[];
  applicationFlows: ApplicationFlow;
  appOwners?: AppOwner[];
  owners: GroupedOwners;
  moreOwners: Owner[];
  shortName: string;
  link: string;
  scoreHistory: {
    date: number;
    score: number;
  }[];
  repoIcon: Nullable<IconType>;
  exclusionComment?: string;
  pipeline: Pipeline;
  isSbomPresent: boolean;
  pipelineScans: number;
  isMonoRepoChild: boolean;
  monoRepoParent: string;
  monorepoChildrenCount: number;
  tags: ITag[];
  apiInventoriesTotal: number;
}

export type GroupedOwners = Partial<
  Record<OneOfValues<typeof AppOwnerRole>, Owner[]>
>;

export interface Owner {
  name: string;
  email: string;
}

export interface PolicyList {
  policyName: string;
  alertCount: number;
  appCount: number;
  oxId: string | number;
}

export interface ApplicationInfo extends BaseApplicationInfo {
  flows: ApplicationFlow;
  lastCodeChangeDate?: string;
  repoIcon: Nullable<IconType>;
}

export interface BaseApplicationRow {
  appId: string;
  appName: string;
  type: RepoTypes;
  fakeApp: boolean;
  categories: Category[];
  risk: number;
  businessPriority: number;
  securityPosture: number;
  appOwners?: AppOwner[];
  issuesBySeverity: SeverityAlert;
  owners: Partial<Record<OneOfValues<typeof AppOwnerRole>, Owner[]>>;
  scoreHistory: {
    date: number;
    score: number;
  }[];
  branchesToScan: string[];
  userCount: number;
  codeChanges: number;
  lastCodeChange?: string;
  exclusionComment: string;
  pipeline: Pipeline;
  isSbomPresent: boolean;
  sbomCount: number;
  isMonoRepoChild: boolean;
  monoRepoParent: string;
  monorepoChildrenCount: number;
  tags: ITag[];
}

export interface setApplicationsStore {
  baseApplications?: BaseApplicationRow[];
  update?: boolean;
  scrollDirection?: string;
  applications?: ApplicationRow[];
}

export interface AppTag {
  appId: string;
  tag: ITag;
}

export const OxTagTypes = {
  owner: 'owner',
  simple: 'simple',
  complex: 'complex',
  githubTopic: 'githubTopic',
  gitlabGroup: 'gitlabGroup',
  ox: 'ox',
} as const;

export type OxTagType = OneOfValues<typeof OxTagTypes>;
export interface ITag {
  tagId: string;
  name: string;
  displayName: string;
  tagType: OxTagType;
  createdBy: string;
}

export type ApplicationsStoreCategories = Pick<
  Category,
  'catId' | 'categoryName'
>[];

export interface ApplicationRow extends BaseApplicationRow {
  categoriesMap: { [key: string]: Category };
  shortName: string;
  repoIcon: Nullable<IconType>;
  lastCodeChangeDate: string;
  pipeline: Pipeline;
}

export enum AppSystemsTypes {
  cicd = 'cicd',
  cloudDeployments = 'cloudDeployments',
}

export interface GetApplicationsInputFilters {
  tags?: string[];
  appId?: string | string[];
  [key: string]: string | string[] | undefined;
}
export interface GetApplicationsInput {
  isAppIdOnly?: boolean;
  limit?: number;
  offset: number;
  applicationFilters?: (InventoryFilterType | ApplicationsFilters)[];
  owners?: string[];
  orderBy?: {
    field?: string;
    direction: Order;
    category?: string;
  };
  filters?: {};
  conditionalFilters?: ConditionalFilter[];
  systemFilter?: {
    name: AppSystemsTypes;
    type: string;
  };
  search?: string;
  scanId?: string;
  appId?: string;
  tagIds?: string[];
  topOffset?: number;
  scrollDirection?: 'bottom' | 'top';
}

export interface FilterApplicationsType {
  categories: FilterElements[];
  repoTypes: FilterElements[];
  orchestrators: FilterElements[];
  artifacts: FilterElements[];
  cicd: FilterElements[];
  cloudDeployments: FilterElements[];
  kubernetes: FilterElements[];
  sca: FilterElements[];
  sast: FilterElements[];
  secretSearch: FilterElements[];
  iac: FilterElements[];
  securityToolSource: FilterElements[];
  appClassification: FilterElements[];
  oxInPipeline: FilterElements[];
  businessPriority: FilterBp;
}

export interface FetchApplicationsResponse {
  getApplicationsFilters: FilterApplicationsType;
}

export interface Pipeline {
  jobId: string;
  jobTriggeredAt: number;
  scanResult: PipelineScanResult;
  issuesCount: number;
  jobTriggeredBy: string;
  jobUrl: string;
}

export enum PipelineScanResult {
  none = 'none',
  monitor = 'monitor',
  block = 'block',
}

export interface GetOrgSbomInput {
  downloadType: DownloadType;
  appId?: string[];
  type?: SbomType;
  filters?: {};
  search?: string;
}

export enum DownloadType {
  CSV = 'CSV',
  JSON = 'JSON',
  PDF = 'PDF',
}

export interface ExportSBOMToCSVResponse {
  appId?: string;
  fileName?: string;
  sbomCSV?: string;
}

export interface QueryResponse {
  requestId: string;
}

export interface AppOwnerOption extends AppOwner {
  numberOfAppearance?: number;
}

export enum AppDrawerTabs {
  ApplicationFlow = 'Application Flow',
  GeneralInfo = 'General Info',
  GitActivity = 'Git Activity',
  OSCAR = 'OSC&R',
  Tags = 'Tags',
  Assigned = 'Assigned',
  Containers = 'Containers',
  PackageContainers = 'Package Containers',
  IssueTrends = 'Issue Trends',
  IssueStatistics = 'Issue Statistics',
}

export type MinifiedApp = Pick<
  ApplicationInfo,
  'appId' | 'appName' | 'type' | 'fakeApp'
>;

export interface BulkActionConfig {
  title: string;
  onClick: () => void;
  disabled: boolean;
  Icon: IconType;
  actionId: string;
  hideAction?: boolean;
  permissions: {
    permissionScope: PermissionScope;
    permissionType: PermissionTypes;
  }[];
}
