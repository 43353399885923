import { resolveRepoIcon } from 'common-icons';
import { formatDistanceToNow } from 'date-fns';
import { uniq } from 'lodash-es';
import { logger } from 'logging-utils';
import {
  AppOwner,
  AppOwnerRole,
  Nullable,
  RepoTypes,
  SeverityType,
  UNATTACHED_EVENTS_APP_TYPE,
} from 'ox-common-types';
import {
  FilterIssueType,
  FilterType,
  FilterTypeToFilter,
} from 'ox-filter-utils';
import { splitLongNames } from 'string-utils';
import { snapshot } from 'valtio';
import {
  ApplicationInfo,
  ApplicationRow,
  BaseApplicationInfo,
  BaseApplicationRow,
  GroupedOwners,
  Owner,
} from '../applications-types';
import ApplicationsStore from '../stores/applications-store';
import { ApplicationFlow } from 'ox-react-components';

export function extractApplications(
  applications: BaseApplicationRow[],
): ApplicationRow[] {
  return (
    applications?.map(app => {
      let lastCodeChangeDate = '';

      if (!app.fakeApp && !shouldIgnoreAppInfo(app.type)) {
        try {
          if (app.lastCodeChange) {
            lastCodeChangeDate = formatDistanceToNow(
              new Date(parseInt(app.lastCodeChange)),
            );
          }
        } catch (err) {
          lastCodeChangeDate = 'Not visibale';
          logger.error(
            `Failed to extract app lastCodeChangeDate "${app.appName}"`,
            err,
          );
        }
      }

      return {
        ...app,
        shortName: splitLongNames(app.appName, 30),
        categoriesMap: app.categories.reduce(
          (acc, category) => ({
            ...acc,
            [category.categoryName || '']: category,
          }),
          {},
        ),
        securityPosture: Math.ceil(app.securityPosture),
        businessPriority: Math.ceil(app.businessPriority),
        repoIcon: resolveRepoIcon(app.type, app.fakeApp),
        lastCodeChangeDate,
        comment: app.exclusionComment,
        pipeline: app.pipeline,
        isSbomPresent: app.isSbomPresent,
      };
    }) || []
  );
}

export const extractSingleApplication = (
  app: BaseApplicationInfo,
): ApplicationInfo => {
  let lastCodeChangeDate = '';
  let owners: typeof app.owners = {};
  let moreOwners: Owner[] = [];
  if (!app.fakeApp && !shouldIgnoreAppInfo(app.type)) {
    try {
      if (app.lastCodeChange) {
        lastCodeChangeDate = formatDistanceToNow(
          new Date(parseInt(app.lastCodeChange)),
        );
      }
    } catch (err) {
      lastCodeChangeDate = 'N/A';
      logger.error(
        `Failed to extract app lastCodeChangeDate "${app.appName}"`,
        err,
      );
    }
    owners = groupOwnersByRole(app.appOwners || []);
    moreOwners = resolveMoreOwners(app.appOwners || []);
  }

  return {
    ...app,
    shortName: splitLongNames(app.appName, 30),
    languages: [...app.languages].sort(
      (a, b) => b.languagePercentage - a.languagePercentage,
    ),
    securityPosture: Math.ceil(app.securityPosture),
    businessPriority: Math.ceil(app.businessPriority),
    repoIcon: resolveRepoIcon(app.type, app.fakeApp),
    flows: app.applicationFlows,
    owners,
    moreOwners,
    lastCodeChangeDate,
  };
};

const shouldIgnoreAppInfo = (appType: string) =>
  appType === UNATTACHED_EVENTS_APP_TYPE;

const groupOwnersByRole = (appOwners: AppOwner[] = []): GroupedOwners => {
  const allRoles = uniq(appOwners.map(o => o.roles).flat());
  return allRoles.reduce((acc, role) => {
    acc[role] = appOwners
      .filter(owner => owner.roles.includes(role))
      .map(o => ({ email: o.email, name: o.name }));
    return acc;
  }, {});
};

const resolveMoreOwners = (appOwners: AppOwner[] = []): Owner[] => {
  const knownRoles = Object.values(AppOwnerRole).toString();
  return appOwners.reduce((acc, appOwner) => {
    const hasCustomRole = appOwner.roles.some(
      role => !knownRoles.includes(role),
    );
    if (hasCustomRole) {
      acc.push({ name: appOwner.name, email: appOwner.email });
    }
    return acc;
  }, [] as Owner[]);
};

export const getSelectedAppsIDs = () => {
  const { selected } = snapshot(ApplicationsStore);
  return Object.keys(selected).filter(key => selected[key]);
};

export const getSelectedApps = (selected: Record<string, boolean>) => {
  const { applications } = snapshot(ApplicationsStore);
  return applications?.filter(app => selected[app.appId]);
};

export const getSelectedAppNames = () => {
  const { selected, allApps } = snapshot(ApplicationsStore);
  return allApps
    ? allApps.reduce((acc, app) => {
        if (selected[app.appId]) {
          acc.push(app.appName);
        }
        return acc;
      }, [] as string[])
    : [];
};

export const getSelectedAppNamesRepoTypesMap = () => {
  const { allApps, selected } = snapshot(ApplicationsStore);
  return allApps.reduce((acc, app) => {
    if (selected[app.appId]) {
      acc[app.appName] = app.type.toLocaleLowerCase();
    }
    return acc;
  }, {});
};

export const getSelectedRepoTypesCountMap = () => {
  const { allApps, selected } = snapshot(ApplicationsStore);
  return allApps.reduce((acc, app) => {
    if (selected[app.appId]) {
      const repoType = app.type.toLowerCase();
      if (acc[repoType]) acc[repoType] += 1;
      else acc[repoType] = 1;
    }
    return acc;
  }, {});
};

export const realFakeApp = (repoType: string) => {
  const checkTypes =
    repoType === RepoTypes.Artifactory ||
    repoType === RepoTypes.AWS ||
    repoType === RepoTypes.Azure ||
    repoType === RepoTypes.ArtifactoryStorage ||
    repoType === RepoTypes.GCP ||
    repoType === RepoTypes.GcpCloud ||
    repoType === RepoTypes.KubernetesCloud ||
    repoType === RepoTypes.GitHubSettings ||
    repoType === RepoTypes.GitHub ||
    repoType === RepoTypes.Kubernetes ||
    repoType === RepoTypes.AzureTFS ||
    repoType === RepoTypes.AzureTfs;
  if (checkTypes) {
    return true;
  }
  return false;
};

export const getAppsNamesFromIDs = (appIDs: string[]) => {
  const { allApps } = snapshot(ApplicationsStore);
  const namesObj: { [key: string]: string } = {};
  appIDs.forEach(appId => {
    const app = allApps?.find(app => app.appId === appId);
    if (app) {
      namesObj[appId] = app.appName;
    }
  });
  return namesObj;
};

export const appsStatisticsFirstRow = [
  { type: FilterTypeToFilter.Category, header: FilterType.category },
  {
    type: FilterTypeToFilter.Criticality,
    header: FilterType.criticality,
  },
  { type: FilterTypeToFilter.IssueOwner, header: FilterType.issueOwners },
  {
    type: FilterTypeToFilter.SourceTool,
    header: FilterType.sourceTools,
  },
  {
    type: FilterTypeToFilter.IssueStatus,
    header: FilterType.issueStatus,
  },
];

export const appsStatisticsSecondtRow = [
  { type: FilterTypeToFilter.IssueNames, header: FilterType.issueNames },
  {
    type: FilterTypeToFilter.Policy,
    header: FilterType.policies,
  },
];

export const resolveCategoryScore = (score: SeverityType) => {
  if (score === SeverityType.Appoxalypse) {
    return 100;
  } else if (score === SeverityType.Critical) {
    return 85;
  } else if (score === SeverityType.High) {
    return 68;
  } else if (score === SeverityType.Medium) {
    return 51;
  } else if (score === SeverityType.Low) {
    return 34;
  } else {
    return 17;
  }
};

export const statisticsExist = (stats: Nullable<FilterIssueType>) => {
  let statsFiltered = {};
  if (stats) {
    if (stats.categories?.length > 0) {
      statsFiltered['categories'] = stats.categories;
    }
    if (stats.criticality?.length > 0) {
      statsFiltered['criticality'] = stats.criticality;
    }
    if (stats.issueOwners?.length > 0) {
      statsFiltered['issueOwners'] = stats.issueOwners;
    }
    if (stats.sourceTools?.length > 0) {
      statsFiltered['sourceTools'] = stats.sourceTools;
    }
    if (stats.issueStatus?.length > 0) {
      statsFiltered['issueStatus'] = stats.issueStatus;
    }
    if (stats.issueNames?.length > 0) {
      statsFiltered['issueNames'] = stats.issueNames;
    }
    if (stats.policies?.length > 0) {
      statsFiltered['policies'] = stats.policies;
    }
  }
  return statsFiltered as FilterIssueType;
};

export const checkArrayLengthsIsZeroAppFlow = (obj: ApplicationFlow) => {
  if (!obj || Object.keys(obj).length === 0) {
    return true;
  }
  for (let key in obj) {
    if (Array.isArray(obj[key]) && obj[key].length !== 0) {
      return false; // Array length is not 0
    }
  }
  return true; // All array lengths are 0
};
