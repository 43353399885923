import { AppPages, navigate } from 'app-navigator';
import { isNull } from 'lodash-es';
import { ChangeEvent } from 'react';
import { snapshot } from 'valtio';
import { Nullable } from '../../../../../utilities/ox-common-types/src/typescript-types';
import TagsStore from '../../tags/store/tags-store';
import { loadSingleApplication } from '../actions/single-application-actions';
import {
  ApplicationInfo,
  ITag,
  setApplicationsStore,
} from '../applications-types';
import { extractApplications } from '../utils/application-utils';
import ApplicationsStore, {
  initialApplicationsStoreValues,
} from './applications-store';
import { logger } from 'logging-utils';

export const applicationsStoreActions = {
  setApplications: (input: setApplicationsStore) => {
    const {
      baseApplications,
      applications,
      update,
      scrollDirection = 'bottom',
    } = input;

    if (!baseApplications && !applications) {
      logger.warn('Setting applications failed, no data provided');
      return;
    }
    if (baseApplications || applications) {
      const formatted = baseApplications
        ? extractApplications(baseApplications)
        : applications;
      if (update) {
        ApplicationsStore.applications = formatted;
      } else {
        if (scrollDirection === 'bottom') {
          ApplicationsStore.applications?.push(...formatted!);
        } else {
          ApplicationsStore.applications = [
            ...formatted!,
            ...(ApplicationsStore.applications || []),
          ];
          applicationsStoreActions.setApplicationsPosition(
            formatted?.length || 0,
          );
        }
      }
    }
  },

  setApplicationsOffset: (offset: number = 0) => {
    ApplicationsStore.offset = offset;
  },

  setApplicationsPosition: (pos: number = 0) => {
    ApplicationsStore.selectedPosition = { pos };
  },

  setApplicationsTopOffset: (offset: number = 0) => {
    ApplicationsStore.topOffset = offset;
  },

  setApplicationsTotal: (total: number) => {
    ApplicationsStore.total = total;
  },

  setIrrelevantApplicationsTotal: (totalIrrelevantApps: number) => {
    ApplicationsStore.totalIrrelevantApps = totalIrrelevantApps;
  },

  setSearchValue: (searchValue: string) => {
    ApplicationsStore.appFilterValue = searchValue;
  },

  selectApplication: (appId: Nullable<string>) => {
    const { applications } = snapshot(ApplicationsStore);
    const currentUrl = new URL(window.location.href);
    const params = new URLSearchParams(currentUrl.search);
    const appListEmpty = applications?.length === 0;

    if (!appId || appListEmpty) {
      applicationsStoreActions.clearSelectedApplication();
      params.delete('appId');
    } else if (appId && !appListEmpty) {
      ApplicationsStore.selectedAppId = appId;
      params.set('appId', appId);

      //TODO: Calling with cache false temoprary soultion
      // until Alex refactor of loadSingleApplication
      loadSingleApplication({
        cache: false,
      });
    }
    navigate(AppPages.Applications, params.toString());
  },

  clearSelectedApplication: () => {
    ApplicationsStore.selectedAppId = null;
    ApplicationsStore.selectedApp = undefined;
  },

  clearSelectedApps: () => {
    const { selected } = snapshot(ApplicationsStore);
    const temp = {};
    Object.keys(selected).forEach(key => (temp[key] = false));
    ApplicationsStore.selected = temp;
  },

  setApplication: (application: ApplicationInfo) => {
    ApplicationsStore.selectedApp = application;
  },

  setLoadingSingleApp: (value: boolean) => {
    ApplicationsStore.loadingSingleApp = value;
  },

  setLastDrawerHeight: (height: number) => {
    ApplicationsStore.lastDrawerHeight = height;
  },

  clearStore: () => {
    Object.keys(initialApplicationsStoreValues).forEach(key => {
      ApplicationsStore[key] = initialApplicationsStoreValues[key];
    });
  },

  setBpForSelectedApp: (bp: number) => {
    ApplicationsStore.bpSelectedApp = bp;
  },

  toggleFiltersPanel: () => {
    ApplicationsStore.filtersOpen = !ApplicationsStore.filtersOpen;
  },

  setExclusionComment: (e: ChangeEvent<HTMLTextAreaElement>) => {
    ApplicationsStore.applications?.forEach(app => {
      if (ApplicationsStore.selected[app.appId]) {
        app.exclusionComment = e.target.value;
      }
    });
  },

  toggleAllSelected: () => {
    const { allSelected, allApps } = snapshot(ApplicationsStore);

    const realApps = allApps?.filter(app => !app.fakeApp).map(app => app.appId);

    const newSelected = Object.fromEntries(
      realApps.map(appName => [appName, !allSelected]),
    );

    ApplicationsStore.selected = newSelected;
  },

  updateAppsTagsChanges: ({
    addedTagsIds,
    appIds,
    removedTagsIds,
  }: {
    addedTagsIds: string[];
    appIds: string[];
    removedTagsIds: string[];
  }) => {
    const { tags: tagsMap } = snapshot(TagsStore);
    const { applications } = snapshot(ApplicationsStore);

    if (isNull(tagsMap) || !!!applications) return;

    const update = applications.map(app => {
      if (!appIds.includes(app.appId)) return app;
      const { tags: oldTags } = app;
      const tags = oldTags.filter(t => !removedTagsIds.includes(t.tagId));
      const mappedAddedTags: ITag[] = addedTagsIds.map(id => {
        return {
          ...tagsMap[id],
        };
      });

      mappedAddedTags.forEach(addedTag => {
        if (!tags.some(t => t.tagId === addedTag.tagId)) tags.push(addedTag);
      });
      return {
        ...app,
        tags,
      };
    });

    ApplicationsStore.applications = [...update];
    applicationsStoreActions.updateSelectedAppDrawerTagsChanges({
      addedTagsIds,
      appIds,
      removedTagsIds,
    });
  },

  updateSelectedAppDrawerTagsChanges: ({
    addedTagsIds,
    appIds,
    removedTagsIds,
  }: {
    addedTagsIds: string[];
    appIds: string[];
    removedTagsIds: string[];
  }) => {
    const { tags: tagsMap } = snapshot(TagsStore);
    const { selectedApp } = snapshot(ApplicationsStore);
    if (!selectedApp) return;
    if (isNull(tagsMap)) return;
    if (!appIds.includes(selectedApp.appId)) return;
    const { tags: oldTags } = selectedApp;
    const tags = oldTags.filter(t => !removedTagsIds.includes(t.tagId));

    const mappedAddedTags: ITag[] = addedTagsIds.map(id => {
      return {
        ...tagsMap[id],
      };
    });

    mappedAddedTags.forEach(addedTag => {
      if (!tags.some(t => t.tagId === addedTag.tagId)) tags.push(addedTag);
    });

    if (ApplicationsStore.selectedApp) {
      ApplicationsStore.selectedApp.tags = tags;
    }
  },

  setIsApplicationDrawerOpen: (isOpen: boolean) => {
    ApplicationsStore.isApplicationDrawerOpen = isOpen;
  },

  displayLimitationModal: () => {
    ApplicationsStore.showLimitationModal = true;
  },

  closeLimitationModal: () => {
    ApplicationsStore.showLimitationModal = false;
  },

  setHoveredRowAppId: (appId: Nullable<string>) => {
    ApplicationsStore.hoveredRowAppId = appId;
  },

  setLoadingDefineBranch: (loading: boolean) => {
    ApplicationsStore.loadingDefineBranch = loading;
  },
};
