import {
  CategoryDisplayName,
  getCategoryByName,
} from '@oxappsec/ox-consolidated-categories';
import { getCurrentTheme } from 'app-theme';
import { generateCategoryIcon } from 'common-icons';
import { IconType, KeyStringGenericObject, Nullable } from 'ox-common-types';
import { capitalizeFirstLetter, splitLongNames } from 'string-utils';
import {
  FeatureFlagKeys,
  FeatureFlags,
} from '../../../apps/web/src/feature-flags/feature-flags-types';

export interface Filter {
  header: FilterType;
  type: FilterTypeToFilter;
  searchType?: FilterTypeSearch;
  featureFlag?: string;
  variant?: FilterVariants;
  isCommonlyUsed?: boolean;
}

export interface FilterIssueType {
  apps: FilterElements[];
  criticality: FilterElements[];
  fixedIssues: FilterElements[];
  categories: FilterElements[];
  policies: FilterElements[];
  issueOwners: FilterElements[];
  issueNames: FilterElements[];
  sourceTools: FilterElements[];
  cwe: FilterElements[];
  issueStatus: FilterElements[];
  uniqueLibs: FilterElements[];
  filePaths: FilterElements[];
  languages: FilterElements[];
  cve: FilterElements[];
  os: FilterElements[];
  baseImage: FilterElements[];
  businessPriority: FilterBp;
}

export interface FilterSbomType {
  apps: FilterElements[];
  libraryNames: FilterElements[];
  libraryVersions: FilterElements[];
  source: FilterElements[];
  dependencyTypes: FilterElements[];
  licenses: FilterElements[];
  packageNames: FilterElements[];
  baseImage: FilterElements[];
  os: FilterElements[];
  registryName: FilterElements[];
  image: FilterElements[];
}

export interface FilterApiInventoryType {
  apps: FilterElements[];
}

export interface SearchInput {
  fieldName: string;
  value: string;
}

export enum FilterType {
  issueStatusVsLastScan = 'Issue Status vs Last Scan',
  apps = 'Application',
  criticality = 'Severity',
  fixedIssues = 'Fixed Issues',
  category = 'Category',
  issueNames = 'Issue Name',
  policies = 'Policy',
  issueOwners = 'Issue Owner',
  appBusinessPriority = 'Business Priority',
  sourceTools = 'Source Tool',
  cwe = 'CWE',
  categories = 'Category',
  repoTypes = 'Source Control',
  orchestrators = 'Orchestrator',
  artifactsType = 'Artifact Type',
  artifactsSystem = 'Registry',
  cicd = 'CI/CD',
  cloudDeployments = 'Cloud Deployment',
  kubernetes = 'Container as a Service',
  sca = 'SCA Coverage',
  sast = 'SAST Coverage',
  secretSearch = 'Secret Coverage',
  iac = 'IAC Coverage',
  securityToolSource = 'Security Tool Source',
  severityChange = 'Severity Reprioritized',
  severityChangeReasons = 'Severity Factor',
  issueStatus = 'Issue Status Over Time',
  issuesActions = 'Actions',
  sourceControl = 'Source Control',
  cloudTechnology = 'Cloud Technology',
  enviorment = 'Enviorment',
  user = 'User',
  version = 'Version',
  executionStatus = 'Execution Status',
  registryType = 'Registry Type',
  jobTriggeredBy = 'Job Triggered By',
  enforcement = 'Result',
  cicdIssueStatus = 'Pipeline Issue Status',
  jobNumber = 'Job Number',
  pullRequests = 'Pull Request',
  libraryNames = 'Library Name',
  libraryVersions = 'Library Version',
  source = 'Source',
  dependencyTypes = 'Dependency',
  licenses = 'License',
  copyrights = 'Copyright',
  packageNames = 'Package Name',
  oxInPipeline = 'Ox In Pipeline',
  packageInfo = 'Issues',
  packageManager = 'Package Manager',
  os = 'Artifact OS Image',
  baseImage = 'Artifact Base Image',
  registryName = 'Registry Name',
  sourceBranch = 'Branch',

  result = 'Result',
  targetBranches = 'Target Branch',
  sourceBranches = 'Scanned Branch',
  severity = 'Vulnerability Severity',
  eventType = 'Event Type',
  originalSeverity = 'Severity Before Prioritization',
  languages = 'Languages',
  pkgManagers = 'Package Manager',
  appName = 'App Name',
  exclusionType = 'Type',
  modifiedBy = 'Created By',
  policyName = 'Policy Name',
  logTypes = 'Log Type',
  logNames = 'Action',
  userEmails = 'User',
  isMonoRepoChild = 'Mono Repo',

  artifactName = 'Artifact',
  UniqueLibs = 'Library',
  FilePaths = 'Files With Issues',
  CVE = 'CVE',
  oscar = 'OSC&R',
  issuesWithout = 'Issues Without',
  appsTags = 'App Tag',
  appClassification = 'App Classification',
  image = 'Artifact Image',

  complianceStandard = 'Compliance Standard',
  complianceControl = 'Compliance Control',

  action = 'Action Name',
  workflowNames = 'Workflow Name',

  resolvedReasons = 'Resolved Reasons',
  endpoints = 'Endpoint',
  titles = 'Title',
  methods = 'Method',
  framework = 'Framework',

  saasCategory = 'SaaS Category',
  saasName = 'SaaS Name',
  detectionType = 'Detection Type',
  Reachability = 'Code-to-Cloud Exposure',
}

export enum FilterTypeToFilter {
  IssueStatusVsLastScan = 'issueStatusVsLastScan',
  Apps = 'apps',
  AppId = 'appId',
  Criticality = 'criticality',
  Category = 'categories',
  IssueNames = 'issueNames',
  issueName = 'issueName',
  Policy = 'policies',
  IssueOwner = 'issueOwners',
  AppBusinessPriority = 'businessPriority',
  SourceTool = 'sourceTools',
  BusinessPriority = 'businessPriority',
  CWE = 'cwe',
  CVSS = 'cvss',
  RepoType = 'repoTypes',
  Orchestrator = 'orchestrators',
  Artifacts = 'artifacts',
  ArtifactsSystem = 'artifactsSystem',
  Cicd = 'cicd',
  CloudDeployments = 'cloudDeployments',
  Cloud = 'cloud',
  Kubernetes = 'kubernetes',
  Sca = 'sca',
  Sast = 'sast',
  SecretSearch = 'secretSearch',
  Iac = 'iac',
  SecurityToolSource = 'securityToolSource',
  SeverityChange = 'severityChange',
  SeverityChangeReasons = 'severityChangeReasons',
  IssueStatus = 'issueStatus',
  OS = 'os',
  BaseImage = 'baseImage',
  registryName = 'registryName',
  IssueActions = 'issueActions',
  SourceControl = 'sourceControl',
  CloudTechnology = 'cloudTechnology',
  Enviorment = 'enviorment',
  User = 'user',
  Version = 'version',
  ExecutionStatus = 'executionStatus',
  ArtifactTypes = 'artifactType',
  JobTriggeredBy = 'jobTriggeredBy',
  Enforcement = 'enforcement',
  CicdIssueStatus = 'cicdIssueStatus',
  JobNumber = 'jobNumber',
  PullRequests = 'pullRequests',
  IssueCategories = 'issueCategories',
  IssueSeverities = 'issueSeverities',
  RegistryType = 'registryType',
  LibraryNames = 'libraryNames',
  LibraryVersions = 'libraryVersions',
  Source = 'source',
  SourceType = 'sourceType',
  SourceBranch = 'sourceBranch',

  DependencyTypes = 'dependencyTypes',
  Licenses = 'licenses',
  Copyright = 'copyrights',
  PackageNames = 'packageNames',
  OxInPipeline = 'oxInPipeline',
  PackageInfo = 'packageInfos',
  PackageManager = 'packageManagers',

  Result = 'result',
  SourceBranches = 'sourceBranches',
  TargetBranches = 'targetBranches',
  Severity = 'severities',
  EventType = 'eventTypes',
  OriginalSeverity = 'originalSeverity',
  Languages = 'languages',
  pkgManagers = 'pkgManagers',
  appName = 'appName',
  exclusionType = 'exclusionType',
  modifiedBy = 'modifiedBy',
  policyName = 'policyName',
  isMonoRepoChild = 'isMonoRepoChild',

  logTypes = 'logTypes',
  logNames = 'logNames',
  userEmails = 'userEmails',

  ArtifactName = 'artifactName',
  FilePaths = 'filePaths',
  UniqueLibs = 'uniqueLibs',
  CVE = 'cve',
  Oscar = 'oscar',
  IssuesWithout = 'issuesWithout',
  Tags = 'tags',
  AppClassification = 'appClassification',
  Image = 'image',

  ComplianceStandard = 'complianceStandard',
  ComplianceControl = 'complianceControl',

  Action = 'actions',
  WorkflowNames = 'workflowNames',

  ResolvedReasons = 'resolvedReasons',

  Titles = 'titles',
  Endpoints = 'endpoints',
  Methods = 'methods',
  Framework = 'framework',

  Name = 'name',
  DetectionType = 'detectionType',
  FirstSeen = 'firstSeen',

  Reachability = 'reachability',
}

export interface FilterDetails {
  name: string;
  count: number;
  id: string;
  percent: number;
  color?: string;
  categoryIcon?: IconType;
  issueOwner?: IssueOwner[];
  splitLabel: string;
  changeNumber: number;
}

enum FilterCriticality {
  Appoxalypse = 'Appoxalypse',
  Critical = 'Critical',
  High = 'High',
  Medium = 'Medium',
  Low = 'Low',
  Info = 'Info',
}

interface IssueOwner {
  name: string;
  email: string;
}

export enum FilterTypeSearch {
  apps = 'apps',
  issueNames = 'issueNames',
  policies = 'policies',
  issueOwners = 'issueOwners',
  issueOwner = 'issueOwner',
  os = 'os',
  baseImage = 'baseImage',
  registryName = 'registryName',

  cwe = 'cwe',
  severityChangeReasons = 'severityChangeReasons',

  jobTriggeredBy = 'jobTriggeredBy',
  pullRequests = 'pullRequests',
  jobNumber = 'jobNumber',

  libraryNames = 'libraryNames',
  libraryVersions = 'libraryVersions',
  packageNames = 'packageNames',
  licenses = 'licenses',
  copyrights = 'copyrights',

  sourceBranches = 'sourceBranches',
  targetBranches = 'targetBranches',
  languages = 'languages',
  appName = 'appName',
  issueName = 'issueName',

  artifactName = 'artifactName',
  version = 'version',
  user = 'user',

  logTypes = 'logTypes',
  logNames = 'logNames',
  userEmails = 'userEmails',
  filePaths = 'filePaths',
  uniqueLibs = 'uniqueLibs',
  cve = 'cve',

  oscar = 'oscar',
  issuesWithout = 'issuesWithout',
  image = 'image',

  complianceStandard = 'complianceStandard',
  complianceControl = 'complianceControl',

  titles = 'titles',
  endpoints = 'endpoints',
  methods = 'methods',
  framework = 'framework',
}

enum filterNoneTypes {
  None = 'None',
}

export interface IssuePrioritization {
  label: string;
  count: number;
}

export interface FilterElements {
  id?: string;
  label: string;
  filterId: string;
  count: number;
  percent: number;
  changeNumber: number;
}

export enum FilterVariants {
  Slider = 'slider',
  MultiSelect = 'multiSelect',
  DateRangeSingleSelect = 'dateRangeSingleSelect',
}

export interface FilterLabel {
  id: FilterTypeToFilter;
  label: FilterType;
  isSearchAble: boolean;
  variant?: Nullable<FilterVariants>;
  minValue?: number;
  maxValue?: number;
  minDistance?: number;
  isCommonlyUsed?: boolean;
  featureFlag?: string;
}

export interface FilterItem extends FilterLabel {
  items: FilterElements[];
  isLoading: boolean;
  isOpen: boolean;
}

export interface FilterData {
  type: string;
  items: FilterElements[];
}

export enum FilterPage {
  Issues = 'Issues',
  Application = 'Application',
  Sboms = 'Sboms',
  Artifacts = 'Artifacts',
  PipelineSummary = 'PipelineSummary',
  IrrelevantApps = 'IrrelevantApps',
  PipelineIssues = 'PipelineIssues',
  DisappearedIssues = 'DisappearedIssues',
  CloudBom = 'CloudBom',
}

export interface FetchFilterLabelsResponse {
  getFilterLabels: FilterLabel[];
}

export interface FilterItems {
  [keyId: string]: FilterItem;
}
export interface LazyFiltersResponse {
  filters: FilterData[];
}

export type LoadFiltersParams = {
  cache?: boolean;
  limit?: number;
  search?: SearchInput[];
  offset?: number;
};

export interface FilterBp {
  max: number;
  min: number;
}

export interface RangeFilterValues {
  greaterThan?: number | null;
  lessThan?: number | null;
}

export interface ConditionalFilter extends RangeFilterValues {
  condition: ConditionalFiltersCondition;
  fieldName: string;
  values: string[];
}

export enum ConditionalFiltersCondition {
  OR = 'OR',
  AND = 'AND',
  NOT = 'NOT',
  BETWEEN = 'BETWEEN',
}

enum FilterCriticalityToSeverity {
  Appoxalypse = 'appox',
  Critical = 'critical',
  High = 'high',
  Medium = 'medium',
  Low = 'low',
  Info = 'info',
}

export const createFilters = (
  filtersType: FilterElements[],
): FilterDetails[] => {
  return (
    filtersType &&
    filtersType.map(filter => ({
      name: filter.label,
      count: filter.count,
      percent: filter.percent,
      id: filter.filterId,
      changeNumber: filter.changeNumber,
      categoryIcon: generateCategoryIcon(filter.label as CategoryDisplayName),
      color:
        getCurrentTheme().palette.categoryRiskColor[
          FilterCriticalityToSeverity[filter.label as FilterCriticality]
        ],
      splitLabel: splitLongNames(filter.label, 24),
    }))
  );
};

export const filterNoneOnlyFilters = (
  filtersList: FilterElements[],
  totalApplications: number,
) => {
  const checkFiltersList =
    (filtersList &&
      filtersList.length > 0 &&
      filtersList?.[0]?.label === filterNoneTypes.None &&
      filtersList?.[0]?.count === totalApplications &&
      filtersList.length === 1) ||
    (filtersList && filtersList.length > 0 && filtersList?.[0]?.label === null);
  if (checkFiltersList) {
    return false;
  }
  return true;
};

export const getFilterItemsLabels = (
  response: Nullable<FilterLabel[]>,
): FilterItems => {
  return response
    ? response.reduce((acc: FilterItems, filterItem) => {
        acc[filterItem.id] = {
          ...filterItem,
          items: [] as FilterElements[],
          isOpen: false,
          isLoading: false,
        };

        return acc;
      }, {})
    : {};
};

export const getOpenFilterItemIds = (filterItems: FilterItems) => {
  return Object.keys(filterItems).reduce((acc: string[], key) => {
    const filterItem = filterItems[key];
    if (filterItem.isOpen) {
      acc.push(filterItem.id);
    } else {
      acc = acc.filter(id => id !== filterItem.id);
    }
    return acc;
  }, []);
};

export const getAllFilterItemsAsArray = (
  filterItems: FilterItems,
  searchValue?: string,
) => {
  const allFilterItems = Object.keys(filterItems)?.map(key => filterItems[key]);
  if (searchValue) {
    return allFilterItems?.filter(filterItem =>
      filterItem.label.toLowerCase().includes(searchValue.toLowerCase()),
    );
  } else {
    return allFilterItems;
  }
};

export const filterEmptyItems = (filters: KeyStringGenericObject<string>) => {
  return Object.keys(filters).reduce(
    (acc: KeyStringGenericObject<string>, key) => {
      if (filters[key].length !== 0) {
        acc[key] = filters[key];
      }
      return acc;
    },
    {},
  );
};

export const filterItemsBySearchValues = (
  lazyFilters: FilterItems,
  searchValues: KeyStringGenericObject<string>,
  filterBy: KeyStringGenericObject<string[]>,
) => {
  return Object.keys(lazyFilters).reduce(
    (acc: FilterItems, filterType: string) => {
      if (searchValues && searchValues[filterType]) {
        const currentFilterData = lazyFilters[filterType].items.filter(
          dataItem => {
            const dataItemLabel = dataItem.label?.toLocaleLowerCase();
            const selectedFilterItems = filterBy[filterType];
            const searchValueItem = searchValues[filterType];
            return (
              dataItemLabel.includes(searchValueItem.toLocaleLowerCase()) ||
              selectedFilterItems?.some(
                item => item.toLocaleLowerCase() === dataItemLabel,
              )
            );
          },
        );
        acc[filterType] = {
          ...lazyFilters[filterType],
          items: currentFilterData,
        };
      } else {
        acc[filterType] = lazyFilters[filterType];
      }
      return acc;
    },
    {},
  );
};

export const getCategoryIdsByNames = (categories?: string[]) => {
  return categories?.map(category => {
    const categoryObj = getCategoryByName(category as CategoryDisplayName);
    return categoryObj?.id || category;
  });
};

export const getNumberOfSelectedFilters = (
  filterIssuesBy: KeyStringGenericObject<string[]>,
  searchValue: string,
  conditionalFilters: ConditionalFilter[],
) => {
  if (conditionalFilters?.length > 0) {
    return conditionalFilters.reduce((accumulator, currentFilter) => {
      if (currentFilter.condition === ConditionalFiltersCondition.BETWEEN) {
        accumulator += 1;
      } else {
        accumulator += currentFilter.values?.length;
      }
      return accumulator;
    }, 0);
  }
  return (
    Object.keys(filterIssuesBy).reduce(
      (acc, key) =>
        key === FilterTypeToFilter.BusinessPriority
          ? acc + 1
          : acc + filterIssuesBy[key].length,
      !!searchValue ? 1 : 0,
    ) || 0
  );
};

export const isFilterUnderFeatureFlag = (
  filter: FilterItem,
  flags: FeatureFlags,
) => {
  const featureFlagKey = `shouldShow${capitalizeFirstLetter(
    filter.id,
  )}Filter` as keyof typeof FeatureFlagKeys;
  const isFlagExist = FeatureFlagKeys.hasOwnProperty(featureFlagKey);
  const isFeatureFlagDisabled = (flag: string) =>
    !flags[flag as keyof typeof flags];
  const filterFeatureFlag = filter.featureFlag;

  const isUnderFeatureFlag =
    (filterFeatureFlag && isFeatureFlagDisabled(filterFeatureFlag)) ||
    (isFlagExist && isFeatureFlagDisabled(featureFlagKey));

  return !isUnderFeatureFlag;
};
