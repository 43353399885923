import { SiMicrosoftteams, SiSlack } from 'react-icons/si';
import SlackLogo from '../../assets/icons/slack.svg';
import TeamsLogo from '../../assets/icons/teams.svg';
import { IssuePages } from '../../issues/common/types/issues-types';

export enum MessagingVendorTypes {
  'Microsoft Teams' = 'Teams', // TODO messaging: remove this when change the name in the connectors service
  Slack = 'Slack',
  Teams = 'Teams',
}

export const messagingVendorIcon = {
  Slack: SiSlack,
  'Microsoft Teams': SiMicrosoftteams, // TODO messaging: remove this when change the name in the connectors service
  Teams: SiMicrosoftteams,
};

export const messagingVendorLogo = {
  Slack: SlackLogo,
  'Microsoft Teams': TeamsLogo, // TODO messaging: remove this when change the name in the connectors service
  Teams: TeamsLogo,
};

export enum MessagingAction {
  SingleIssueMessage = 'SingleIssueMessage',
  BulkIssuesMessage = 'BulkIssuesMessage',
  WorkflowsMessage = 'WorkflowsMessage',
}

export enum MessagingRecipientsTypes {
  User = 'User',
  Channel = 'Channel',
}

export interface MessagingRecipient {
  id: string;
  name: string;
  type: MessagingRecipientsTypes;
}

export interface SendMessageInput {
  page: IssuePages;
  selectedMessagingVendor: MessagingVendorTypes;
  selectedRecipients: MessagingRecipient[];
  selectedAction: MessagingAction;
  selectedIssuesIds: string[];
  comment: string;
}

export enum MessagingActionsPages {
  CurrentIssues = 'CurrentIssues',
  PipelineIssues = 'PipelineIssues',
  PolicyWorkflows = 'PolicyWorkflows',
}
