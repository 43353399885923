import { AppPages, navigate } from 'app-navigator';
import { ConditionalFilter, FilterData, FilterItems } from 'ox-filter-utils';
import ApplicationsFiltersStore from './applications-filters-store';
import ApplicationsStore from './applications-store';

export const applicationsFiltersStoreActions = {
  setTotalAppsFilters: (totalFilters: number) => {
    ApplicationsFiltersStore.totalFilteredApps = totalFilters;
  },

  setSliderBusinessPriority: (range: number[]) => {
    ApplicationsFiltersStore.sliderBusinessPriority = range;
  },

  clearFiltersInStore: () => {
    ApplicationsFiltersStore.filterBy = {};
    ApplicationsFiltersStore.numberOfFilters = 0;
    ApplicationsFiltersStore.sliderBusinessPriority = [0, 100];
    ApplicationsFiltersStore.totalFilteredApps = ApplicationsStore.total;
    ApplicationsFiltersStore.appsSearchValues = [];
    ApplicationsFiltersStore.selectedConditionalFilters = [];
  },

  clearAllFilters: () => {
    applicationsFiltersStoreActions.clearFiltersInStore();
    navigate(AppPages.Applications);
  },

  setSearchValues: (searchValue: string, type: string) => {
    ApplicationsFiltersStore.searchValues[type] = searchValue;
  },

  setAppsSearchValues: (searchValue: string, type: string) => {
    const index = ApplicationsFiltersStore.appsSearchValues.findIndex(
      key => key.fieldName === type,
    );
    if (index !== -1) {
      ApplicationsFiltersStore.appsSearchValues[index].value = searchValue;
    } else {
      ApplicationsFiltersStore.appsSearchValues.push({
        fieldName: type,
        value: searchValue,
      });
    }
    ApplicationsFiltersStore.searchValues[type] = searchValue;
  },

  setStoreApplicationsOpenFilters: (
    resultsFilters: FilterData[],
    filterItems: FilterItems,
  ) => {
    Object.keys(filterItems).forEach(key => {
      const selectedResultFilterItem = resultsFilters.find(
        item => item.type === key,
      );
      if (
        ApplicationsFiltersStore.lazyFilters &&
        ApplicationsFiltersStore.lazyFilters[key] &&
        selectedResultFilterItem
      ) {
        ApplicationsFiltersStore.lazyFilters[key].items =
          selectedResultFilterItem.items;
      }
    });
  },

  setSelectedConditionalFilters: (conditionalFilters: ConditionalFilter[]) => {
    ApplicationsFiltersStore.selectedConditionalFilters = conditionalFilters;
  },

  setIsLoadingFilters: (isLoadingFilters: boolean) => {
    ApplicationsFiltersStore.isLoadingFilters = isLoadingFilters;
  },

  setFiltersBy: (filterBy: {}) => {
    ApplicationsFiltersStore.filterBy = filterBy;
  },
};
