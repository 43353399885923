import { AppPages, navigateWithFilters } from 'app-navigator';
import { createSimpleAsyncAction } from 'async-utils';
import { debounce } from 'lodash-es';
import { logger } from 'logging-utils';
import { Nullable } from 'ox-common-types';
import {
  ConditionalFilter,
  ConditionalFiltersCondition,
  FilterItems,
  FilterPage,
  FilterTypeToFilter,
  RangeFilterValues,
  getFilterItemsLabels,
} from 'ox-filter-utils';
import { snapshot } from 'valtio';
import {
  decodeFromUrl,
  navigateWithParameters,
} from '../../../../../../utilities/app-navigator/src/app-navigator';
import getFilterLabels from '../../../../../../utilities/ox-filter-utils/src/get-filter-labels/get-filter-labels';
import {
  getIssuesOpenFilterItems,
  resetSelected,
  setConditionalFiltersInStore,
  setSearchValues,
} from '../store-actions/issue-store-actions';
import IssuesStore from '../stores/issues-store';
import { conditionalFiltersUtils } from '../../common/utils/conditional-filters-utils';

export const setIssueFilterLabels = createSimpleAsyncAction(
  async () => {
    const { filterItems } = snapshot(IssuesStore);

    if (!filterItems) {
      const results = await getFilterLabels(FilterPage.Issues);
      const filterItems: FilterItems = getFilterItemsLabels(results);

      resetSelected();
      IssuesStore.filterItems = filterItems;
    }
  },
  {
    asyncState: IssuesStore.loadingFilters,
    errorMessage: 'Failed to load issues filter labels',
  },
);

export const onChangeConditionalFilter = (
  conditionalFiltersUpdated: ConditionalFilter[],
) => {
  setConditionalFiltersInStore(conditionalFiltersUpdated);
  navigateWithParameters(AppPages.ActiveIssues, {
    conditionalFilters: conditionalFiltersUpdated,
  });
};

export const setFilter = (
  filterType: string,
  filterName: string,
  only?: boolean,
) => {
  let currentUrl = new URL(window.location.href);
  if (only) {
    const filters = { [filterType]: [filterName] };
    navigateWithFilters(currentUrl.pathname, filters);
  } else {
    let params = new URLSearchParams(currentUrl.search);
    try {
      const filtersStr = params.get('filters') || '{}';
      const filters = JSON.parse(filtersStr) || {};
      const hasFilters = filters[filterType] !== undefined;
      const index = hasFilters ? filters[filterType].indexOf(filterName) : -1;

      if (index !== -1) {
        filters[filterType].splice(index, 1);
      } else {
        if (filters[filterType]?.length) {
          filters[filterType].push(filterName);
        } else {
          filters[filterType] = [filterName];
        }
      }

      if (filters[filterType]?.length === 0) {
        // in case of no filters, remove the querystring altogether
        navigateWithFilters(currentUrl.pathname, {});
      } else {
        navigateWithFilters(currentUrl.pathname, filters);
      }
    } catch (err) {
      logger.error('failed to parse filters', err);
    }
  }
};

export const setConditionalFiltersFromUrl = (
  conditionalFiltersFromUrl: Nullable<string>,
  filtersFromUrl: Nullable<string>,
) => {
  try {
    if (filtersFromUrl) {
      const filters = JSON.parse(decodeURI(filtersFromUrl));
      const conditionalFilters =
        conditionalFiltersUtils.translateFiltersToConditionalFilters(filters);
      setConditionalFiltersInStore(conditionalFilters);
    } else if (conditionalFiltersFromUrl) {
      const decodedConditionalFilters = decodeFromUrl(
        conditionalFiltersFromUrl,
      ) as ConditionalFilter[];
      if (decodedConditionalFilters && decodedConditionalFilters.length > 0) {
        setConditionalFiltersInStore(decodedConditionalFilters);
      }
    }
  } catch {
    logger.error(
      'Failed to parse filters in setInitialConditionalFilters function',
    );
  }
};

export const handleChangeConditionalFilterSlider = (
  range: number[] | number,
  filterName: FilterTypeToFilter,
) => {
  if (!Array.isArray(range)) return;

  const greaterThan = range[0];
  const lessThan = range[1];
  const { conditionalFilters } = snapshot(IssuesStore);
  const conditionalFilterUpdated = conditionalFilters?.filter(
    filterItem => filterItem.fieldName !== filterName,
  );
  const newSliderFilter: ConditionalFilter = {
    condition: ConditionalFiltersCondition.BETWEEN,
    fieldName: filterName,
    values: [],
    greaterThan: greaterThan,
    lessThan: lessThan,
  };
  const updatedFilters = [...conditionalFilterUpdated, newSliderFilter];

  navigateWithParameters(AppPages.ActiveIssues, {
    conditionalFilters: updatedFilters,
  });
};

export const handleChangeDateFilter = debounce(
  (range: RangeFilterValues, filterName: FilterTypeToFilter) => {
    const { conditionalFilters } = snapshot(IssuesStore);
    const conditionalFilterUpdated = conditionalFilters?.filter(
      filterItem => filterItem.fieldName !== filterName,
    );
    const newDateFilter: ConditionalFilter = {
      condition: ConditionalFiltersCondition.BETWEEN,
      fieldName: filterName,
      values: [],
      greaterThan: range.greaterThan,
      lessThan: range.lessThan,
    };
    const updatedFilters = [...conditionalFilterUpdated, newDateFilter];

    navigateWithParameters(AppPages.ActiveIssues, {
      conditionalFilters: updatedFilters,
    });
  },
  500,
);

export const handleSearchInOpenFilterItems = debounce(
  (searchInput: string, filterType: string) => {
    setSearchValues(filterType, searchInput);
    getIssuesOpenFilterItems(filterType, true);
  },
  500,
);
