import { logger } from 'logging-utils';
import { openErrorSnackbar, openSnackbar } from 'snackbar-utils';
import { IssuePages } from '../../issues/common/types/issues-types';
import { getStoreByPage } from '../../issues/common/utils/store-utils';
import { messagingUtils } from '../messaging-utils';
import messagingService from '../services';
import { messagingStoreActions } from '../store-actions/messaging-store-actions';
import {
  MessagingAction,
  MessagingVendorTypes,
  SendMessageInput,
} from '../types/messaging-types';

export const messagingActions = {
  openSendMessageModal: (params: {
    page: IssuePages;
    selectedMessagingVendor: MessagingVendorTypes;
    selectedIssuesIds: string[];
    selectedAction: MessagingAction;
  }) => {
    if (!messagingUtils.validateSendMessageParams(params)) {
      logger.error('Missing required fields for opening send message modal');
      return;
    }
    const { page, selectedMessagingVendor, selectedIssuesIds, selectedAction } =
      params;
    messagingStoreActions.setIsShowMessagingModal(true);
    messagingStoreActions.setSelectedMessagingVendor(selectedMessagingVendor);
    messagingStoreActions.setSelectedAction(selectedAction);
    messagingStoreActions.setSelectedIssuesIds(selectedIssuesIds);
    messagingStoreActions.setPage(page);
  },

  closeSendMessageModal: () => {
    messagingStoreActions.resetStore();
  },

  sendMessage: async (params: SendMessageInput) => {
    if (
      !messagingUtils.validateSendMessageParams(params) ||
      params.selectedRecipients?.length === 0
    ) {
      logger.error('Missing required fields for sending message');
      return;
    }

    const {
      selectedMessagingVendor,
      selectedIssuesIds,
      comment,
      selectedRecipients,
      page,
    } = params;

    const messageApiParams = {
      messagingVendor: MessagingVendorTypes[selectedMessagingVendor],
      issuesIds: selectedIssuesIds,
      comment,
      recipients: selectedRecipients,
    };

    messagingStoreActions.setIsLoadingSendMessage(true);
    const response = await messagingService.sendMessage.execute(
      messageApiParams,
    );
    messagingStoreActions.setIsLoadingSendMessage(false);
    if (!response?.sentSuccessfully) {
      openErrorSnackbar('Failed to send message');
      messagingStoreActions.setIsErrorSendingMessage(true);
      return;
    }
    const store = getStoreByPage(page);
    store.issues.forEach(issue => {
      if (selectedIssuesIds.includes(issue.issueId)) {
        issue.messages?.push({
          messagingVendor: MessagingVendorTypes[selectedMessagingVendor],
          recipients: selectedRecipients,
          createdAt: new Date(),
        });
      }
    });
    openSnackbar('Message sent successfully', { variant: 'success' });
    messagingActions.closeSendMessageModal();
  },

  getRecipients: async (selectedMessagingVendor: MessagingVendorTypes) => {
    if (!selectedMessagingVendor) {
      logger.error('Missing required fields for getting recipients');
      return;
    }

    messagingStoreActions.setIsLoadingRecipients(true);
    const response = await messagingService.getRecipients.execute(
      MessagingVendorTypes[selectedMessagingVendor],
    );
    messagingStoreActions.setIsLoadingRecipients(false);
    if (!response) {
      openErrorSnackbar('Failed to get recipients');
      messagingStoreActions.setIsErrorLoadingRecipients(true);
      return;
    }

    messagingStoreActions.setAllRecipients(response);
  },
};
